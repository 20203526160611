'use es6';
export const COURSE_DATA_REQUESTED= 'COURSE_DATA_REQUESTED';
export const COURSE_DATA_RECIEVED= 'COURSE_DATA_RECIEVED';
export const COURSE_DATA_FAILED= 'COURSE_DATA_FAILED';
export const PROFESSOR_DATA_TERM_REQUESTED= 'PROFESSOR_DATA_TERM_REQUESTED';
export const PROFESSOR_DATA_TERM_RECIEVED= 'PROFESSOR_DATA_TERM_RECIEVED';
export const PROFESSOR_DATA_TERM_FAILED= 'PROFESSOR_DATA_TERM_FAILED';
export const PROFESSOR_DATA_SECTION_REQUESTED= 'PROFESSOR_DATA_SECTION_REQUESTED';
export const PROFESSOR_DATA_SECTION_RECIEVED= 'PROFESSOR_DATA_SECTION_RECIEVED';
export const PROFESSOR_DATA_SECTION_FAILED= 'PROFESSOR_DATA_SECTION_FAILED';
export const PROFESSOR_DATA_SECTION_UPDATED= 'PROFESSOR_DATA_SECTION_UPDATED';
