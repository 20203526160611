import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { computeWeightedGradeAverages } from '../utils/averagingUtils';
import { DesktopOnly } from "../utils/screenWidth";
import { CompareTwoTone } from "@material-ui/icons";
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import infoIcon from "../icons/info.png";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import StarRating from "./StarRating";
import { Row, Col } from "react-bootstrap";

const AvgCiosTable = ({ finalCiosAvgs, isProf }) => {
    const [completedAverages, setCompletedAverages] = useState({});
    const [moreOrLess, setMoreOrLess] = useState("more");
    const [viewMore, setViewMore] = useState(false);

    useEffect(() => {
        setCompletedAverages(roundAvgs(finalCiosAvgs));
    }, [])

    const fiveRatios = ["instructor_effectiveness", "instructor_helpfulness", "instructor_availability",
                        "instructor_stimulates_interest", "instructor_enthusiasm", "instructor_respectfulness",
                        "instructor_communication", "instructor_clarity", "course_effectiveness",
                        "assignment_measured_knowledge", "amount_learned"];
    const percentages = ["pct_classes_attended", "pct_homework_completed"];
    const percentagesToFix = ["pct_responded"];
    const other = ["hours_per_week", "weight"];

    const roundAvgs = (finalCiosAvgs) => {
        const dict = {};
        Object.keys(finalCiosAvgs).forEach((key) => {
            if (fiveRatios.includes(key) || percentages.includes(key) || other.includes(key)) {
                dict[key] = finalCiosAvgs[key].toFixed(1);
            } else if (percentagesToFix.includes(key)) {
                dict[key] = (finalCiosAvgs[key] * 100).toFixed(1);
            }
        })
        return dict;
    }

    const moreOrLessToggle = (currentState) => {
        if (currentState === "more") {
            setMoreOrLess("less");
            setViewMore(true);
        } else {
            setMoreOrLess("more");
            setViewMore(false);
        }
    }

    return (
        <div className={"cios-container"}>
            <Row style={{ justifyContent: "space-between" }}>
                <Col>
                    <div className="cios-table-element">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    CIOS survey data. Number of responses: {completedAverages.weight}. 
                                    Note that Georgia Tech does not officially cite these statistics,
                                    and that CIOS data is subject to bias.
                                </Tooltip>
                            }
                        >
                            <span className="info-icon">
                                <AiOutlineInfoCircle />
                            </span>
                        </OverlayTrigger>
                        <h4 style={{ fontWeight: 'bold', whiteSpace: 'nowrap', fontSize: '14px', color: "#8d8d8d" }}>CIOS Ratings</h4>
                    </div>
                    {isProf ? (
                        <>
                            <div className="cios-table-element">
                                <StarRating rating={completedAverages.instructor_effectiveness}/>
                                <h4 className="cios-table-entry">{completedAverages.instructor_effectiveness}</h4>
                                <h4 className="cios-table-entry">&#9679; Instructor Effectiveness</h4>
                            </div>
                            <div className="cios-table-element">
                                <StarRating rating={completedAverages.instructor_helpfulness}/>
                                <h4 className="cios-table-entry">{completedAverages.instructor_helpfulness}</h4>
                                <h4 className="cios-table-entry">&#9679; Instructor Helpfulness</h4>
                            </div>
                            <div className="cios-table-element">
                                <h4 className="cios-table-entry">{completedAverages.pct_classes_attended}%</h4>
                                <h4 className="cios-table-entry">&#9679; Class Attendance</h4>
                            </div>
                            <div className={`cios-view-more ${viewMore ? 'visible' : 'hidden'}`} key={viewMore}>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.instructor_availability}/>
                                    <h4 className="cios-table-entry">{completedAverages.instructor_availability}</h4>
                                    <h4 className="cios-table-entry">&#9679; Instructor Availability</h4>
                                </div>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.instructor_stimulates_interest}/>
                                    <h4 className="cios-table-entry">{completedAverages.instructor_stimulates_interest}</h4>
                                    <h4 className="cios-table-entry">&#9679; Instructor Stimulates Interest</h4>
                                </div>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.instructor_enthusiasm}/>
                                    <h4 className="cios-table-entry">{completedAverages.instructor_enthusiasm}</h4>
                                    <h4 className="cios-table-entry">&#9679; Instructor Enthusiasm</h4>
                                </div>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.instructor_respectfulness}/>
                                    <h4 className="cios-table-entry">{completedAverages.instructor_respectfulness}</h4>
                                    <h4 className="cios-table-entry">&#9679; Instructor Respectfulness</h4>
                                </div>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.instructor_communication}/>
                                    <h4 className="cios-table-entry">{completedAverages.instructor_communication}</h4>
                                    <h4 className="cios-table-entry">&#9679; Instructor Communication</h4>
                                </div>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.instructor_clarity}/>
                                    <h4 className="cios-table-entry">{completedAverages.instructor_clarity}</h4>
                                    <h4 className="cios-table-entry">&#9679; Instructor Clarity</h4>
                                </div>
                                <div className="cios-table-element">
                                    <h4 className="cios-table-entry">{completedAverages.pct_responded}%</h4>
                                    <h4 className="cios-table-entry">&#9679; Response Rate</h4>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="cios-table-element">
                                <StarRating rating={completedAverages.course_effectiveness}/>
                                <h4 className="cios-table-entry">{completedAverages.course_effectiveness}</h4>
                                <h4 className="cios-table-entry">&#9679; Course Effectiveness</h4>
                            </div>
                            <div className="cios-table-element">
                                <StarRating rating={completedAverages.amount_learned}/>
                                <h4 className="cios-table-entry">{completedAverages.amount_learned}</h4>
                                <h4 className="cios-table-entry">&#9679; Amount Learned</h4>
                            </div>
                            <div className="cios-table-element">
                                <h4 className="cios-table-entry">{completedAverages.hours_per_week}</h4>
                                <h4 className="cios-table-entry">&#9679; Hours Per Week</h4>
                            </div>
                            <div className={`cios-view-more ${viewMore ? 'visible' : 'hidden'}`} key={viewMore}>
                                <div className="cios-table-element">
                                    <StarRating rating={completedAverages.assignment_measured_knowledge}/>
                                    <h4 className="cios-table-entry">{completedAverages.assignment_measured_knowledge}</h4>
                                    <h4 className="cios-table-entry">&#9679; Assignments Measured Knowledge</h4>
                                </div>
                                <div className="cios-table-element">
                                    <h4 className="cios-table-entry">{completedAverages.pct_homework_completed}%</h4>
                                    <h4 className="cios-table-entry">&#9679; Homework Completion</h4>
                                </div>
                                <div className="cios-table-element">
                                    <h4 className="cios-table-entry">{completedAverages.pct_classes_attended}%</h4>
                                    <h4 className="cios-table-entry">&#9679; Class Attendance</h4>
                                </div>
                                <div className="cios-table-element">
                                    <h4 className="cios-table-entry">{completedAverages.pct_responded}%</h4>
                                    <h4 className="cios-table-entry">&#9679; Response Rate</h4>
                                </div>
                            </div>
                        </>
                    )}

                    <button className="view-more-button link" onClick={() => moreOrLessToggle(moreOrLess)}>
                        View {moreOrLess}
                    </button>
                </Col>
            </Row>
        </div>
    )
}
export default AvgCiosTable;
