import React from "react";
import { Col, Row } from 'react-bootstrap'
import SearchBar from './SearchBar'
import DarkModeButton from './DarkModeButton'
import { Link } from 'react-router-dom';
import { DesktopOnly, MobileOnly } from "../utils/screenWidth";

const PageHeader = () => {

  return (
    <>
      <DesktopOnly>
        <Row style={{ marginTop: '1vh' }}>
          <Col>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <p class="course-title" style={{ textAlign: 'left', fontWeight: '700', cursor: "pointer", fontSize: '24px', textDecoration: "none" }}>Course <a style={{ color: '#E8C343' }}>Critique</a></p>
            </Link>
          </Col>
          <Col>
            <SearchBar format={3} />
          </Col>
          <Col>
            <DarkModeButton />
          </Col>
        </Row>
      </DesktopOnly>
      
      <MobileOnly>
        <Row style={{ marginTop: '1em' }}>
          <Col xs={8}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <p class="course-title" style={{ textAlign: 'left', fontWeight: '700', cursor: "pointer", fontSize: '24px', textDecoration: "none" }}>Course <a style={{ color: '#E8C343' }}>Critique</a></p>
            </Link>
          </Col>
          <Col xs={4}>
            <DarkModeButton />
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchBar format={3} />
          </Col>
        </Row>
      </MobileOnly>
    </>)
};

export default PageHeader;