import React from "react";
import "../style/pageHeader.css";
import PageHeader from './PageHeader';
import AvgGradesTable from './AvgGradesTable';
import AvgCiosTable from "./AvgCiosTable";
import { Col, Row } from "react-bootstrap";

export default function ProfessorPageHeader({ profDetails, finalProfAvgs, finalCiosAvgs }) {
  
  if (finalProfAvgs === null) {
    return (<></>);
  }

  return (
    <div className="page-header">
      <PageHeader />
      <h2 className="text-center" style={{fontSize: "30px"}}>{ profDetails.instructor_name }</h2>
      <Row style={{ justifyContent: "spaceBetween", margin: 0 }}>
        <Col style={{ maxWidth: "65%" }}>
          <AvgGradesTable finalAvgs={finalProfAvgs}/>
        </Col>
        <Col style={{ maxWidth: "35%" }}>
          <AvgCiosTable finalCiosAvgs={finalCiosAvgs} isProf={true}/>
        </Col>
        </Row>
    </div>
  );

}



