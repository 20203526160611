'use es6'

import { API_BASE_URL } from "../constants/Endpoints";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CourseTable from './CourseTable';
import MobileCourseTable from './MobileCourseTable';
import DropdownButton from 'react-bootstrap/DropdownButton'
import CoursePageHeader from './CoursePageHeader';
import { Redirect } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Footer from './footer';
import { DesktopOnly, MobileOnly } from "../utils/screenWidth";
import { computeFinalCiosAvgs, 
    computeFinalGradeAvgs,
    computeWeightedGradeAverages,
    weightsMap
} from "../utils/averagingUtils";
import { getProfFilter,
    getTermFilter,
    getGPAFilter,
    isGPAInFilteredRange,
    getClassSizeFilter,
    roundAvgs,
    extractYearFromString,
    computeTermsActive
} from "../utils/helperUtils";
import LoadingPage from "./LoadingPage";
import { computeAvgGradesOverTime } from './AvgGradesOverTime';
import AvgGradesOverTime from "./AvgGradesOverTime";

const CoursePage = ({courseId}) => {

    const [courseData, setCourseData] = useState([]);
    const [courseDetails, setCourseDetails] = useState({});
    const [relatedCourses, setRelatedCourses] = useState("");
    const [profAvgs, setProfAvgs] = useState({});
    const [roundedProfAvgs, setRoundedProfAvgs] = useState({});
    const [ciosData, setCiosData] = useState([]);
    const [finalCourseAvgs, setFinalCourseAvgs] = useState([]);
    const [finalCiosAvgs, setFinalCiosAvgs] = useState({});
    
    const [allProfToggle, setAllProfToggle] = useState(true);
    const [profFilter, setProfFilter] = useState({});
    const [allTermToggle, setAllTermToggle] = useState(true);
    const [termFilter, setTermFilter] = useState({});
    const [allGPAToggle, setAllGPAToggle] = useState(true);
    const [gpaFilter, setGPAFilter] = useState({});
    const [classSizeToggle, setClassSizeToggle] = useState(true);
    const [classSizeFilter, setClassSizeFilter] = useState({});
    const [noCios, setNoCios] = useState(false);
    const [shouldComponentRender, setShouldComponentRender] = useState(false);
    const [rerenderKey, setRerenderKey] = useState(0);
    const [termsActiveDict, setTermsActiveDict] = useState({});
    const [avgGradesOverTime, setAvgGradesOverTime] = useState({});
    const [coreStatistics, setCoreStatistics] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [courseResponse, ciosResponse] = await Promise.all([
                    fetch(`${API_BASE_URL}/course?courseID=${courseId}`),
                    fetch(`${API_BASE_URL}/CIOS?courseID=${courseId}`)
                ]);

                const courseData = await courseResponse.json();
                const ciosData = await ciosResponse.json();

                setCourseDetails(courseData.header[0]);
                setCourseData(courseData.raw);
                setRelatedCourses(courseData.relatedCourses[0]["related_courses"]);
        
                if (ciosData[0] == null) {
                    setNoCios(true);
                }
                setCiosData(ciosData);

                setProfFilter(getProfFilter(courseData.raw));
                setTermFilter(getTermFilter(courseData.raw));
                setGPAFilter(getGPAFilter());
                setClassSizeFilter(getClassSizeFilter());

                setAvgGradesOverTime(computeAvgGradesOverTime(courseData.raw));

            } catch (error) {
                console.log(error);
            }
        };
      
        fetchData();
      }, [courseId]);

    useEffect(() => {
        const setFinalAvgs = async () => {
            const professorGrades = computeWeightedGradeAverages(courseData, profFilter, termFilter, classSizeFilter);
            const finalAvgsCourse = computeFinalGradeAvgs(courseData, profFilter, termFilter, classSizeFilter);
            const finalAvgsCios = computeFinalCiosAvgs(ciosData, {}, profFilter, termFilter, classSizeFilter);
            const termsActive = computeTermsActive(courseData, termFilter);
            console.log(termsActive);

            setFinalCourseAvgs(finalAvgsCourse);
            setFinalCiosAvgs(finalAvgsCios);
            setTermsActiveDict(termsActive);
            if (rerenderKey <= 4) { // <-- only compute this on first necessary renders
                setProfAvgs(professorGrades);
            }
            setRoundedProfAvgs(roundAvgs(professorGrades));

            setRerenderKey(prevKey => prevKey + 1);
            console.log(rerenderKey);

            if (finalAvgsCourse && (noCios || Object.keys(finalAvgsCios).length > 1) && rerenderKey >= 4) {
                setShouldComponentRender(true);
            } else {
                return <Redirect to={`/404`} />
            }
        };

        setFinalAvgs();
    }, [profFilter, termFilter, classSizeFilter]);

    useEffect(() => {
        const updatedFilter = {};
        Object.keys(profAvgs).forEach((profName) => {
            if (!isGPAInFilteredRange(profAvgs[profName]["GPA"], gpaFilter)) {
                updatedFilter[profName] = false;
            } else {
                updatedFilter[profName] = true;
            }
        })
        setProfFilter(updatedFilter);
    }, [gpaFilter])

    /* ---- PROFESSOR TOGGLES ------- */

    const toggleAllProfessors = () => {
        setAllProfToggle(!allProfToggle);
        const updatedFilter = {};
        Object.keys(profFilter).forEach((profName) => {
            updatedFilter[profName] = !allProfToggle; // allProfToggle hasn't been updated yet
        });
        setProfFilter(updatedFilter);
    };

    const toggleProfessor = (prof) => {
        const updatedFilter = {...profFilter};
        updatedFilter[prof] = !updatedFilter[prof];
        setProfFilter(updatedFilter);
        if (!updatedFilter[prof]) {
            setAllProfToggle(false);
        }
    };

    const profFilterDropdown = () => {
        return (
            Object.entries(profFilter).map(([prof, value]) => (
                <div key={prof}>
                    <input type="checkbox" checked={value} key={prof}
                        style={{ marginLeft: "15px", padding: "1px" }}
                        onChange={() => toggleProfessor(prof)}
                    />
                    <span> {prof}</span>
                </div>
            ))
        )
    };

    /* ---- TERM TOGGLES ------- */

    const toggleAllTerms = () => {
        setAllTermToggle(!allTermToggle);
        const updatedFilter = {};
        Object.keys(termFilter).forEach((term) => {
            updatedFilter[term] = !allTermToggle;
        });
        setTermFilter(updatedFilter);
    };

    const toggleTerm = (term) => {
        const updatedFilter = {...termFilter};
        updatedFilter[term] = !updatedFilter[term];
        setTermFilter(updatedFilter);
        if (!updatedFilter[term]) {
            setAllTermToggle(false);
        }
    };

    const termFilterDropdown = () => {
        const sortedTerms = Object.entries(termFilter)
            .sort((a, b) => extractYearFromString(b[0]) - extractYearFromString(a[0]))
            .map(([term, value]) => (
                <div key={term}>
                    <input
                        type="checkbox"
                        checked={value}
                        style={{ marginLeft: "15px", padding: "1px" }}
                        onChange={() => toggleTerm(term)}
                    />
                    <span> {term}</span>
                </div>
            ));

        return sortedTerms;
    };

    /* ---- GPA TOGGLES ------- */

    const toggleAllGPAs = () => {
        setAllGPAToggle(!allGPAToggle);
        const updatedFilter = {};
        Object.keys(gpaFilter).forEach((GPA) => {
            updatedFilter[GPA] = !allGPAToggle;
        });
        setGPAFilter(updatedFilter);
    };

    const toggleGPA = (GPA) => {
        const updatedFilter = {...gpaFilter};
        updatedFilter[GPA] = !updatedFilter[GPA];
        setGPAFilter(updatedFilter);
        if (!updatedFilter[GPA]) {
            setAllGPAToggle(false);
        }
    };

    const gpaFilterDropdown = () => {
        return (
            Object.entries(gpaFilter).map(([GPA, value]) => (
                <div key={GPA}>
                    <input type="checkbox" checked={value} key={GPA}
                        style={{ marginLeft: "15px", padding: "1px" }}
                        onChange={() => toggleGPA(GPA)}
                    />
                    <span> {GPA}</span>
                </div>
            ))
        )
    };

    /* ---- CLASS SIZE TOGGLES ------- */

    const toggleAllSizes = () => {
        setClassSizeToggle(!classSizeToggle);
        const updatedFilter = {};
        Object.keys(classSizeFilter).forEach((size) => {
            updatedFilter[size] = !classSizeToggle;
        })
        setClassSizeFilter(updatedFilter);
    }

    const toggleClassSize = (size) => {
        const updatedFilter = {...classSizeFilter};
        updatedFilter[size] = !updatedFilter[size];
        setClassSizeFilter(updatedFilter);
        if (!updatedFilter[size]) {
            setClassSizeToggle(false);
        }
    }

    const classSizeDropdown = () => {
        return (    
            Object.entries(classSizeFilter).map(([size, value]) => (
                <div key={size}>
                    <input type="checkbox" checked={value} key={size}
                        style={{ marginLeft: "15px", padding: "1px"}}
                        onChange={()=>toggleClassSize(size)}/>
                    <span> {weightsMap[size]}</span>
                </div>
            ))
        )   
    }

    /* ---- RENDER ------- */
    
    if (!shouldComponentRender) {
        return (
            <LoadingPage/>
        )
    } else {
        return (
            <>
            <Container>
                <div key={rerenderKey}>
                    <CoursePageHeader
                        courseDetails={courseDetails}
                        finalCourseAvgs={finalCourseAvgs}
                        finalCiosAvgs={finalCiosAvgs}
                    />
                </div>

                <Row className="justify-content-end">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ margin: 0, marginRight: "1em", fontSize: '14px', color: "#909090" }}>
                            Changing filters will recompute averages.
                        </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {(!allGPAToggle || !allTermToggle || !allProfToggle || !classSizeToggle) 
                            && (<p style={{ margin: 0, marginRight: "1em" }}>Active Filters</p>)}
                        {!classSizeToggle && (<p style={{ width: "fit-content", margin: 0, marginRight: "1em" }}>Class Size </p>)} 
                        {!allGPAToggle && (<p style={{ margin: 0, marginRight: "1em" }}>GPA </p>)} 
                        {!allTermToggle && (<p style={{ margin: 0, marginRight: "1em" }}>Term </p>)} 
                        {!allProfToggle && (<p style={{ margin: 0, marginRight: "1em" }}>Prof</p>)}
                    </div>
                    <DropdownButton id="classSizeList" title="Class Size" variant="info" style={{ marginRight: '1em' }}>
                        <input type="checkbox" checked={classSizeToggle} key="allSize" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllSizes()}
                        /><span> (ALL)</span>
                        {classSizeDropdown()}
                    </DropdownButton>
                    <DropdownButton id="gpaList" title="GPA" variant="info" style={{ marginRight: '1em' }}>
                        <input type="checkbox" checked={allGPAToggle} key="allGPA" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllGPAs()}
                        /><span> (ALL)</span>
                        {gpaFilterDropdown()}
                    </DropdownButton>
                    <DropdownButton id="termList" title="Terms" variant="info" style={{ marginRight: '1em' }}>
                        <input type="checkbox" checked={allTermToggle} key="allTerm" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllTerms()}
                        /><span> (ALL)</span>
                        {termFilterDropdown()}
                    </DropdownButton>
                    <DropdownButton id="profList" title="Professors" variant="info">
                        <input type="checkbox"checked={allProfToggle} key="allCourse" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllProfessors()}
                        /><span> (ALL)</span>
                        {profFilterDropdown()}
                    </DropdownButton>
                </Row>

                <Row style={{marginBottom: "1em"}}>
                    <DesktopOnly>
                        <CourseTable
                            professorGradeAvgs={roundedProfAvgs}
                            termsActive={termsActiveDict}
                        />
                    </DesktopOnly>
                    <MobileOnly>
                        <MobileCourseTable
                            professorGradeAvgs={roundedProfAvgs}
                            termsActive={termsActiveDict}
                        />
                    </MobileOnly>
                </Row>

                {/* <Container>
                    <AvgGradesOverTime
                        avgGradesOverTime={avgGradesOverTime}
                    />
                </Container> */}

                <Col style={{ width: '35%', marginLeft: 'auto' }}>
                    <Row style={{ justifyContent: 'right' }}>
                        <h4 style={{ margin: 0, marginTop: '1em' }}>See related courses</h4>
                    </Row>
                    <Row style={{ marginLeft: 'auto', justifyContent: "space-between" }}>
                        {relatedCourses.split(",").map((course, index) => (
                            <Link key={index} to={`/course?courseID=${course}`} className="link">
                                {course}
                            </Link>
                        ))}
                    </Row>
                    
                </Col>

                <Row><Footer /></Row>
            </Container>
            </>
        )
    }
}

export default CoursePage;