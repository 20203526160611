import React from 'react';
import ReactTooltip from 'react-tooltip';
import Badge from 'react-bootstrap/Badge';
// import info from '../icons/info.png';
import InformationIcon from '@material-ui/icons/Info';
import { colors } from "../constants/Colors.js";

function PillLegend () {
    return(
        <div style={{ display: 'inline', paddingLeft: '5px', paddingRight: '3px' }}>
            <InformationIcon data-tip data-for="legend"/>
            <ReactTooltip id="legend" place="top" effect="solid" backgroundColor="grey">
                <Badge pill variant="primary" style={{ backgroundColor: colors["aqua"]}}>
                    <div style={{ color: "black"}}>{'>=3.5'}</div>
                </Badge>
                <Badge pill variant="primary" style={{ backgroundColor: colors["green"], marginLeft: '0.75em' }}>
                    <div style={{ color: "black"}}>{'>=3.0'}</div>
                </Badge>
                <Badge pill variant="primary" style={{ backgroundColor: colors["yellow"], marginLeft: '0.75em' }}>
                    <div style={{ color: "black"}}>{'>=2.5'}</div>
                </Badge>
                <Badge pill variant="primary" style={{ backgroundColor: colors["orange"], marginLeft: '0.75em' }}>
                    <div style={{ color: "black"}}>{'>=2.0'}</div>
                </Badge>
                <Badge pill variant="primary" style={{ backgroundColor: colors["red"], marginLeft: '0.75em' }}>
                    <div style={{ color: "black"}}>{'>=1.5'}</div>
                </Badge>
                <Badge pill variant="primary" style={{ backgroundColor: colors["gray"], marginLeft: '0.75em' }}>
                    <div style={{ color: "black"}}>{'>=0.0'}</div>
                </Badge>           
            </ReactTooltip>
        </div>
    )
}
export default PillLegend;