import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { Row } from "react-bootstrap";

const StarRating = ({ rating, translucent }) => {
    const roundedRating = Math.round(rating * 2) / 2;
    const filledStars = Math.floor(roundedRating);
    
    const stars = Array.from({ length: filledStars }, (_, index) => (
        <FontAwesomeIcon key={index} icon={fasStar} style={{ color: '#E9C343' }} />
    ));

    if (roundedRating > filledStars) {
        stars.push(
            <FontAwesomeIcon key="partialStar" icon={faStarHalfAlt} style={{ color: '#E9C343' }} />
        );
    }

    const emptyStars = Array.from({ length: 5 - filledStars - (roundedRating > filledStars ? 1 : 0) }, (_, index) => (
        <FontAwesomeIcon key={`emptyStar${index}`} icon={farStar} style={{ color: '#E9C343' }} />
    ));
  
    return (
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '1.2em', opacity: `${translucent ? '0' : '100'}` }}>
            {stars}
            {emptyStars}
        </div>
    );
};

export default StarRating;