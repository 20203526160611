'use es6'

import {API_BASE_URL} from "../constants/Endpoints";
import React, { useState, useEffect, useRef } from "react";
import ProfessorTable from './ProfessorTable'
import ProfessorPageHeader from './ProfessorPageHeader';
import MobileProfTable from './MobileProfTable';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { Redirect } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import DropdownButton from 'react-bootstrap/DropdownButton'
import "../style/pageHeader.css"
import Footer from './footer';
import LoadingPage from "./LoadingPage";
import { DesktopOnly, MobileOnly } from "../utils/screenWidth";
import {
    getCourseFilter,
    getTermFilter,
    getClassSizeFilter,
    getGPAFilter,
    roundAvgs,
    isGPAInFilteredRange,
    extractYearFromString,
    filterData
} from "../utils/helperUtils";
import {
    computeWeightedProfAvgs,
    computeFinalProfAvgs,
    computeFinalCiosAvgs,
    weightsMap
} from "../utils/averagingUtils";

const ProfessorPage = ({ professorId }) => {

    const [profData, setProfData] = useState([]);
    const [filteredProfData, setFilteredProfData] = useState([]);
    const [profDetails, setProfDetails] = useState({});
    const [courseAvgs, setCourseAvgs] = useState({});
    const [ciosData, setCiosData] = useState([]);
    const [finalProfAvgs, setFinalProfAvgs] = useState([]);
    const [finalCiosAvgs, setFinalCiosAvgs] = useState({});

    const [allCourseToggle, setAllCourseToggle] = useState(true);
    const [courseFilter, setCourseFilter] = useState({});
    const [allTermToggle, setAllTermToggle] = useState(true);
    const [termFilter, setTermFilter] = useState({});
    const [allGPAToggle, setAllGPAToggle] = useState(true);
    const [gpaFilter, setGPAFilter] = useState({});
    const [classSizeToggle, setClassSizeToggle] = useState(true);
    const [classSizeFilter, setClassSizeFilter] = useState({});
    const [noCios, setNoCios] = useState(false);
    const [shouldComponentRender, setShouldComponentRender] = useState(false);
    const [rerenderKey, setRerenderKey] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [profResponse, ciosResponse] = await Promise.all([
                    fetch(`${API_BASE_URL}/prof?profID=${professorId}&by=section`),
                    fetch(`${API_BASE_URL}/CIOS?profID=${professorId}`)
                ]);

                const profData = await profResponse.json();
                const ciosData = await ciosResponse.json();

                setProfDetails(profData.header[0]);
                setProfData(profData.raw);
        
                if (ciosData[0] == null) {
                    setNoCios(true);
                }
                setCiosData(ciosData);
                setCourseFilter(getCourseFilter(profData.raw));
                setTermFilter(getTermFilter(profData.raw));
                setGPAFilter(getGPAFilter());
                setClassSizeFilter(getClassSizeFilter());

            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [professorId]);

    useEffect(() => {
        const setFinalAvgs = async () => {
            const courseGrades = computeWeightedProfAvgs(profData, courseFilter, gpaFilter, termFilter, classSizeFilter);
            const finalAvgsProf = computeFinalProfAvgs(profData, courseFilter, gpaFilter, termFilter, classSizeFilter);
            const finalAvgsCios = computeFinalCiosAvgs(ciosData, courseFilter, {}, termFilter, classSizeFilter);
            const filteredData = filterData(profData, courseFilter, gpaFilter, termFilter, classSizeFilter);

            setFinalProfAvgs(finalAvgsProf);
            setFinalCiosAvgs(finalAvgsCios);
            setFilteredProfData(filteredData);
            if (rerenderKey <= 4) { // <-- only compute this on first necessary renders
                setCourseAvgs(courseGrades);
            }

            setRerenderKey(prevKey => prevKey + 1);
            console.log(rerenderKey);

            if (finalAvgsProf && (noCios || Object.keys(finalAvgsCios).length > 1) && rerenderKey >= 4) {
                setShouldComponentRender(true);
            } else {
                return <Redirect to={`/404`} />
            }
        };

        setFinalAvgs();
    }, [courseFilter, termFilter, classSizeFilter, gpaFilter]);

    /* ---- COURSE TOGGLES ------- */

    const toggleAllCourses = () => {
        setAllCourseToggle(!allCourseToggle);
        const updatedFilter = {};
        Object.keys(courseFilter).forEach((courseName) => {
            updatedFilter[courseName] = !allCourseToggle;
        });
        setCourseFilter(updatedFilter);
    };

    const toggleCourse = (course) => {
        const updatedFilter = {...courseFilter};
        updatedFilter[course] = !updatedFilter[course];
        setCourseFilter(updatedFilter);
        if (!updatedFilter[course]) {
            setAllCourseToggle(false);
        }
    };

    const courseFilterDropdown = () => {
        return (
            Object.entries(courseFilter).map(([course, value]) => (
                <div key={course}>
                    <input type="checkbox" checked={value} key={course}
                        style={{ marginLeft: "15px", padding: "1px" }}
                        onChange={() => toggleCourse(course)}
                    />
                    <span> {course}</span>
                </div>
            ))
        )
    };

    /* ---- TERM TOGGLES ------- */

    const toggleAllTerms = () => {
        setAllTermToggle(!allTermToggle);
        const updatedFilter = {};
        Object.keys(termFilter).forEach((term) => {
            updatedFilter[term] = !allTermToggle; // allYearToggle hasn't been updated yet
        });
        setTermFilter(updatedFilter);
    };

    const toggleTerm = (term) => {
        const updatedFilter = {...termFilter};
        updatedFilter[term] = !updatedFilter[term];
        setTermFilter(updatedFilter);
        if (!updatedFilter[term]) {
            setAllTermToggle(false);
        }
    };

    const termFilterDropdown = () => {
        const sortedTerms = Object.entries(termFilter)
            .sort((a, b) => extractYearFromString(b[0]) - extractYearFromString(a[0]))
            .map(([term, value]) => (
                <div key={term}>
                    <input
                        type="checkbox"
                        checked={value}
                        style={{ marginLeft: "15px", padding: "1px" }}
                        onChange={() => toggleTerm(term)}
                    />
                    <span> {term}</span>
                </div>
            ));

        return sortedTerms;
    };

    /* ---- GPA TOGGLES ------- */

    const toggleAllGPAs = () => {
        setAllGPAToggle(!allGPAToggle);
        const updatedFilter = {};
        Object.keys(gpaFilter).forEach((GPA) => {
            updatedFilter[GPA] = !allGPAToggle;
        });
        setGPAFilter(updatedFilter);
    };

    const toggleGPA = (GPA) => {
        const updatedFilter = {...gpaFilter};
        updatedFilter[GPA] = !updatedFilter[GPA];
        setGPAFilter(updatedFilter);
        if (!updatedFilter[GPA]) {
            setAllGPAToggle(false);
        }
    };

    const gpaFilterDropdown = () => {
        return (
            Object.entries(gpaFilter).map(([GPA, value]) => (
                <div key={GPA}>
                    <input type="checkbox" checked={value} key={GPA}
                        style={{ marginLeft: "15px", padding: "1px" }}
                        onChange={() => toggleGPA(GPA)}
                    />
                    <span> {GPA}</span>
                </div>
            ))
        )
    };

    /* ---- CLASS SIZE TOGGLES ------- */

    const toggleAllSizes = () => {
        setClassSizeToggle(!classSizeToggle);
        const updatedFilter = {};
        Object.keys(classSizeFilter).forEach((size) => {
            updatedFilter[size] = !classSizeToggle;
        })
        setClassSizeFilter(updatedFilter);
    }

    const toggleClassSize = (size) => {
        const updatedFilter = {...classSizeFilter};
        updatedFilter[size] = !updatedFilter[size];
        setClassSizeFilter(updatedFilter);
        if (!updatedFilter[size]) {
            setClassSizeToggle(false);
        }
    }

    const classSizeDropdown = () => {
        return (    
            Object.entries(classSizeFilter).map(([size, value]) => (
                <div key={size}>
                    <input type="checkbox" checked={value} key={size}
                        style={{ marginLeft: "15px", padding: "1px"}}
                        onChange={()=>toggleClassSize(size)}/>
                    <span> {weightsMap[size]}</span>
                </div>
            ))
        )   
    }

    if (!shouldComponentRender) {
        return (
            <LoadingPage/>
        )
    } else {
        return (
            <Container>
                <div key={rerenderKey}>
                    <ProfessorPageHeader
                        profDetails={profDetails}
                        finalProfAvgs={finalProfAvgs}
                        finalCiosAvgs={finalCiosAvgs}
                    />
                </div>
                <Row className="justify-content-end">
                <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ margin: 0, marginRight: "1em", fontSize: '14px', color: "#909090" }}>
                            Changing filters will recompute averages.
                        </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {(!allGPAToggle || !allTermToggle || !allCourseToggle || !classSizeToggle) 
                            && (<p style={{ margin: 0, marginRight: "1em" }}>Active Filters</p>)}
                        {!classSizeToggle && (<p style={{ width: "fit-content", margin: 0, marginRight: "1em" }}>Class Size </p>)} 
                        {!allGPAToggle && (<p style={{ margin: 0, marginRight: "1em" }}>GPA </p>)} 
                        {!allTermToggle && (<p style={{ margin: 0, marginRight: "1em" }}>Term </p>)} 
                        {!allCourseToggle && (<p style={{ margin: 0, marginRight: "1em" }}>Course</p>)}
                    </div>
                    <DropdownButton id="classSizeList" title="Class Size" variant="info" style={{ marginRight: '1em' }}>
                        <input type="checkbox" checked={classSizeToggle} key="allSize" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllSizes()}
                        /><span> (ALL)</span>
                        {classSizeDropdown()}
                    </DropdownButton>
                    <DropdownButton id="gpaList" title="GPA" variant="info" style={{ marginRight: '1em' }}>
                        <input type="checkbox" checked={allGPAToggle} key="allGPA" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllGPAs()}
                        /><span> (ALL)</span>
                        {gpaFilterDropdown()}
                    </DropdownButton>
                    <DropdownButton id="termList" title="Terms" variant="info" style={{ marginRight: '1em' }}>
                        <input type="checkbox" checked={allTermToggle} key="allTerm" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllTerms()}
                        /><span> (ALL)</span>
                        {termFilterDropdown()}
                    </DropdownButton>
                    <DropdownButton id="courseList" title="Courses" variant="info">
                        <input type="checkbox"checked={allCourseToggle} key="allCourse" 
                            style={{marginLeft: "15px", padding: "1px"}}
                            onChange={() => toggleAllCourses()}
                        /><span> (ALL)</span>
                        {courseFilterDropdown()}
                    </DropdownButton>
                </Row>
                
                <Row style={{ marginBottom: "2em" }}>
                    <DesktopOnly>
                        <ProfessorTable
                            profData={filteredProfData}
                        />
                    </DesktopOnly>
                    <MobileOnly>
                        <MobileProfTable
                            profData={filteredProfData}
                        />
                    </MobileOnly>
                </Row>
                <Row><Footer /></Row>
            </Container>
        )
    }
}

export default ProfessorPage;