import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Container from 'react-bootstrap/Container';
import rmp from '../images/rmp.png';
import Badge from 'react-bootstrap/Badge';
import PillLegend from './PillLegend.jsx';
import "../style/pageTable.css";
import { colors } from "../constants/Colors.js";
import DescriptionIcon from '@material-ui/icons/Description';
import BookIcon from '@material-ui/icons/Book';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import { AUTH_URL } from "../constants/Endpoints";
import { extractYearFromString } from "../utils/helperUtils.js";

function useWindowSize() {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function resizing() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", resizing);
    resizing();
    
    return () => window.removeEventListener("resize", resizing);
  }, []);
  
  return size;
}

const handleSyllabiClick = (row) => {
    window.open(`${window.location.href.split('/')[0]}/sso?key=${row.s3_key}`);
}

const instructorFormatter = (cell, row) => {
  if (cell != null) {
    return (
      <div className="course-header">
        <Link to={`/prof?profID=${row.instructor_gt_username}&fromCourse=${row.courseId}`}>{row.instructor_name}</Link>
        {/* {
          row.s3_key != null &&
          <Tooltip title="Syllabus">
            <IconButton size="small" style={{outline: "none", marginLeft: "3px"}} color="inherit" aria-label="Syllabus" onClick={() => handleSyllabiClick(row)}>
              <BookIcon />
            </IconButton>
          </Tooltip>
        } */}
      </div>
    )
  }
}

const termsActiveFormatter = (cell, row) => {
  const selectedTerms = cell.selected_terms || [];
  const unselectedTerms = cell.unselected_terms || [];
  const allTerms = [...selectedTerms, ...unselectedTerms].sort((a, b) => extractYearFromString(b) - extractYearFromString(a));

  const formattedTerms = allTerms.map(term => {
    const isSelected = selectedTerms.includes(term);

    return (
      <span
        key={term}
        style={{
          padding: '2px 2px',
          margin: '2px',
          display: 'inline-block',
          color: isSelected ? undefined : 'gray', // Use undefined for default color
        }}
      >
        {term}
      </span>
    );
  });

  return <div>{formattedTerms}</div>;
};

const pillBadges = (cell, row) => {
  if (cell != null) {
    var color = colors["gray"];
    if (row.GPA >= 3.5) {
      color = colors["aqua"];
    } else if (row.GPA >= 3) {
      color = colors["green"];
    } else if (row.GPA >= 2.5) {
      color = colors["yellow"];
    } else if (row.GPA >= 2) {
      color = colors["orange"];
    } else if (row.GPA >= 1.5) {
      color = colors["red"];
    }

    let avgGPA = row.GPA ? row.GPA.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "";

    return (
      <h5 style={{ padding: '0', margin: '0', lineHeight: '0' }}>
        <Badge pill variant="primary" style={{ backgroundColor: color }}>
          <div style={{ color: "black"}}>{avgGPA}</div>
        </Badge>
      </h5>
    )
  }
}

const CourseTable = ({ professorGradeAvgs, termsActive }) => {
  const ref = React.useRef();
  const displayArray = Object.keys(professorGradeAvgs).map(instructor => ({
    instructor_name: instructor,
    terms_active: termsActive[instructor],
    ...professorGradeAvgs[instructor],
  }));

  return (
    // min height of table: 500px
    <Container>
      <div style={{ position: 'relative', height: 240, maxWidth:"100%", margin: "auto", marginTop: "1em", marginBottom: '4em' }}>
        <BootstrapTable tableHeaderClass="course-table-head" trClassName="course-table" striped condensed ref={ref} data={displayArray}>
          <TableHeaderColumn dataField='instructor_name' dataFormat={instructorFormatter} isKey={true} width='15%' 
            dataSort={true} tdStyle={{ whiteSpace: 'normal' }}>Instructor</TableHeaderColumn>
          <TableHeaderColumn dataField='terms_active' dataFormat={termsActiveFormatter} width='20%' dataSort={true} 
            tdStyle={{ whiteSpace: 'normal' }}>Terms Active</TableHeaderColumn>
          <TableHeaderColumn dataField='GPA' width='15%' dataSort={true} dataFormat={pillBadges}>GPA
            <PillLegend />
          </TableHeaderColumn>
          <TableHeaderColumn dataField='A' width='10%' dataSort={true}>A</TableHeaderColumn>
          <TableHeaderColumn dataField='B' width='10%' dataSort={true}>B</TableHeaderColumn>
          <TableHeaderColumn dataField='C' width='10%' dataSort={true}>C</TableHeaderColumn>
          <TableHeaderColumn dataField='D' width='10%' dataSort={true}>D</TableHeaderColumn>
          <TableHeaderColumn dataField='F' width='10%' dataSort={true}>F</TableHeaderColumn>
          <TableHeaderColumn dataField='W' width='10%' dataSort={true}>W</TableHeaderColumn>
        </BootstrapTable>
      </div>
    </Container>
  )
}

export default CourseTable