import { extractYearFromString, getClassSize, isGPAInFilteredRange } from "./helperUtils";

const params = ["amount_learned", "assignment_measured_knowledge", "course_effectiveness", "hours_per_week", 
"instructor_availability", "instructor_clarity", "instructor_communication",
"instructor_effectiveness", "instructor_enthusiasm", "instructor_helpfulness", "instructor_respectfulness",
"instructor_stimulates_interest", "num_responses", "num_total", "pct_classes_attended", 
"pct_homework_completed", "pct_responded"];
/**
 * Computes weighted CIOS averages and returns as dict.
 * @param {*} ciosData raw cios data
 * @param {*} profFilter filter for profs
 * @returns 
 */
export const computeWeightedCiosAverages = (ciosData, courseFilter, profFilter, termFilter, sizeFilter) => {
  const dict = {};
  for (const data of ciosData) {
    let entry = {...data};
    let prof = entry["instructor"];
    let course = entry["course_id"];
    let term = entry["term"];
    let size = getClassSize(entry["num_total"]);
    if ((Object.keys(courseFilter).length != 0 && !courseFilter[course]) || (Object.keys(profFilter).length != 0 && !profFilter[prof])) {
      continue;
    } else if (!termFilter[term] || ((Object.keys(sizeFilter).length != 0 && !sizeFilter[size]))) {
      continue;
    }
    if (!(prof in dict)) {
      dict[prof] = entry;
      dict[prof]["weight"] = entry["num_responses"];
    } else {
      for (const param of params) {
        dict[prof][param] = (dict[prof][param] * dict[prof]["weight"] + entry[param] * entry["num_responses"]) / (dict[prof]["weight"] + entry["num_responses"]);
      }
      dict[prof]["weight"] += entry["num_responses"];
    }
  }
  return dict;
}

/**
 * Computes the final CIOS avg from weightedCiosAvg dictionary.
 * @param {*} ciosData dictionary of raw cios data by professor
 * @param {*} profFilter list of relevant professors
 * @returns dictionary containing each param value and a rounded floating value
 */
export const computeFinalCiosAvgs = (ciosData, courseFilter, profFilter, termFilter, sizeFilter) => {
  const weightedCiosData = computeWeightedCiosAverages(ciosData, courseFilter, profFilter, termFilter, sizeFilter);
  const dict = {};
  dict["weight"] = 0;
  Object.values(weightedCiosData).forEach((value) => {
    let entry = {...value};
    for (const param of params) {
      if (!(param in dict)) {
        dict[param] = entry[param];
      } else {
        dict[param] = (dict[param] * dict["weight"] + entry[param] * entry["weight"]) / (dict["weight"] + entry["weight"]);
      }
    }
    dict["weight"] += entry["weight"];
  })
  Object.keys(dict).forEach((key) => {
    dict[key] = +(dict[key].toFixed(2));
  })
  return dict;
}

const letters = ["GPA", "A", "B", "C", "D", "F", "W"];
export const weights = {
  'very small (fewer than 10 students)': 5,
  'small (10-20 students)': 15,
  'mid-size (21-30 students)': 25,
  'large (31-49 students)': 40,
  'very large (50 students or more)': 50
};
export const weightsMap = {
  'very small (fewer than 10 students)': 'Very Small',
  'small (10-20 students)': 'Small',
  'mid-size (21-30 students)': 'Mid-size',
  'large (31-49 students)': 'Large',
  'very large (50 students or more)': 'Very Large'
};
/**
 * Computes weighted course grade averages for each professor in raw course data.
 * @param {*} courseData raw course data fetched from API
 * @param {*} profFilter list of relevant professors (true/false)
 * @returns dictionary with key = {prof} and values = {row corresponding to weighted prof avgs}
 */
export const computeWeightedGradeAverages = (courseData, profFilter, termFilter, sizeFilter) => {
  const dict = {};
  for (const data of courseData) {
    let entry = {...data};
    let prof = entry["instructor_name"];
    let term = entry["Term"];
    let size = entry["class_size_group"].toLowerCase();
    if (!profFilter[prof] || !termFilter[term] || (Object.keys(sizeFilter).length != 0 && !sizeFilter[size])) {
      continue;
    }
    let currWeight = weights[size];
    if (!(prof in dict)) {
      dict[prof] = entry;
      dict[prof]["weight"] = currWeight;
    } else {
      for (const letter of letters) {
        dict[prof][letter] = (dict[prof][letter] * dict[prof]["weight"] + entry[letter] * currWeight) / (dict[prof]["weight"] + currWeight);
      }
      dict[prof]["weight"] += currWeight;
    }
  }
  return dict;
}

/**
 * Calls weighted grade averages, then averages each of the entries by its weight to return a final dictionary.
 * @param {*} courseData raw course data fetched from API
 * @param {*} profFilter list of relevant professors (true/false)
 * @returns dictionary of letters -> rounded values
 */
export const computeFinalGradeAvgs = (courseData, profFilter, termFilter, sizeFilter) => {
  const weightedCourseData = computeWeightedGradeAverages(courseData, profFilter, termFilter, sizeFilter);
  const dict = {};
  dict["weight"] = 0;
  Object.values(weightedCourseData).forEach((value) => {
    let entry = {...value};
    for (const letter of letters) {
      if (!(letter in dict)) {
        dict[letter] = entry[letter];
      } else {
        dict[letter] = (dict[letter] * dict["weight"] + entry[letter] * entry["weight"]) / (dict["weight"] + entry["weight"]);
      }
    }
    dict["weight"] += entry["weight"];
  })
  Object.keys(dict).forEach((key) => {
    if (["A", "B", "C", "D", "F", "W"].includes(key)) {
      dict[key] = +(dict[key].toFixed(1));
    } else {
      dict[key] = +(dict[key].toFixed(2));
    }
  })
  return dict;
}

/**
 * Computes weighted course avgs based on prof data.
 * @param {*} profData raw prof data
 * @param {*} courseFilter course filter
 * @param {*} termFilter term filter
 * @param {*} sizeFilter size filter
 * @returns dictionary of averages course -> grades
 */
export const computeWeightedProfAvgs = (profData, courseFilter, gpaFilter, termFilter, sizeFilter) => {
  const dict = {};
  for (const data of profData) {
    let entry = {...data};
    let course = entry["course_id"];
    let term = entry["Term"];
    let size = entry["class_size_group"].toLowerCase();
    let gpa = entry["GPA"];
    if (!courseFilter[course] || !isGPAInFilteredRange(gpa, gpaFilter) || !termFilter[term] 
          || (Object.keys(sizeFilter).length != 0 && !sizeFilter[size])) {
      continue;
    }
    let currWeight = weights[size];
    if (!(course in dict)) {
      dict[course] = entry;
      dict[course]["weight"] = currWeight;
    } else {
      for (const letter of letters) {
        dict[course][letter] = (dict[course][letter] * dict[course]["weight"] + entry[letter] * currWeight) / (dict[course]["weight"] + currWeight);
      }
      dict[course]["weight"] += currWeight;
    }
  }
  return dict;
}

/**
 * Computes final course avgs based on prof data. Calls weightedCourseAvgs.
 * @param {*} profData raw prof data
 * @param {*} courseFilter course filter
 * @param {*} termFilter year filter
 * @param {*} sizeFilter size filter
 * @returns final dictionary of grades to values
 */
export const computeFinalProfAvgs = (profData, courseFilter, gpaFilter, termFilter, sizeFilter) => {
  const weightedProfData = computeWeightedProfAvgs(profData, courseFilter, gpaFilter, termFilter, sizeFilter);
  const dict = {};
  dict["weight"] = 0;
  Object.values(weightedProfData).forEach((value) => {
    let entry = {...value};
    for (const letter of letters) {
      if (!(letter in dict)) {
        dict[letter] = entry[letter];
      } else {
        dict[letter] = (dict[letter] * dict["weight"] + entry[letter] * entry["weight"]) / (dict["weight"] + entry["weight"]);
      }
    }
    dict["weight"] += entry["weight"];
  })
  Object.keys(dict).forEach((key) => {
    if (["A", "B", "C", "D", "F", "W"].includes(key)) {
      dict[key] = +(dict[key].toFixed(1));
    } else {
      dict[key] = +(dict[key].toFixed(2));
    }
  })
  return dict;
}