import { useMediaQuery } from "react-responsive";

const minDesktopWidth = 992;

export const DesktopOnly = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: minDesktopWidth })
  return isDesktop ? children : null
}

export const MobileOnly = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: minDesktopWidth - 1 })
  return isMobile ? children : null
}
