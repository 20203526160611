import Spinner from 'react-bootstrap/Spinner';
import React from "react";

const LoadingPage = () => {
    return (
        <div className="loadDiv">
            <Spinner style={{ display: 'inline-block' }} animation="grow" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
            <Spinner style={{ display: 'inline-block' }} animation="grow" role="status" variant="warning">
                <span className="sr-only">Loading...</span>
            </Spinner>
            <Spinner style={{ display: 'inline-block' }} animation="grow" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>

    )
}

export default LoadingPage