import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Container from 'react-bootstrap/Container';
import "../style/pageTable.css";
import Badge from 'react-bootstrap/Badge';
import PillLegend from './PillLegend.jsx';
import { colors } from "../constants/Colors.js"
import BookIcon from '@material-ui/icons/Book';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import { AUTH_URL } from "../constants/Endpoints";


function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? '#ebecf0' : '#f9f9f9', fontSize: "15px" };
}

function termSort(term1A, term2A, order) {
  let term1 = "";
  let term2 = "";
  if(order == "desc") {
    term1 = term1A.Term;
    term2 = term2A.Term;
  } else {
    term1 = term2A.Term;
    term2 = term1A.Term;
  }

  let term1S = term1.split(" ");
  let term2S = term2.split(" ");
  let seasons = {
      "Spring" : 1,
      "Summer" : 2,
      "Fall" : 3
  };
  if(term1S[1] > term2S[1])
      return -1;
  else if(term1S[1] < term2S[1])
      return 1;
  else if(term1S[1] === term2S[1] && seasons[term1S[0]] > seasons[term2S[0]])
      return -1;
  else if(term1S[1] === term2S[1] && seasons[term1S[0]] < seasons[term2S[0]])
      return 1;
}

function useWindowSize() {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function resizing() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", resizing);
    resizing();
    
    return () => window.removeEventListener("resize", resizing);
  }, []);
  
  return size;
}


const pillBadges = (cell, row) => {
  if(cell != null) {
    var color = colors["gray"];
    if (row.GPA >= 3.5) {
      color = colors["aqua"];
    } else if (row.GPA >= 3) {
      color = colors["green"];
    } else if (row.GPA >= 2.5) {
      color = colors["yellow"];
    } else if (row.GPA >= 2) {
      color = colors["orange"];
    } else if (row.GPA >= 1.5) {
      color = colors["red"];
    }

    return (
      <h5 style={{ padding: '0', margin: '0', lineHeight: '0' }}>
        <Badge pill variant="primary" style={{ backgroundColor: color }}>
          <div style={{ color: "black"}}>{row.GPA}</div>
        </Badge>
      </h5>
    )
  }
}

const sizeTruncator = (cell, row) => {
    if (row.class_size_group.includes("Very Small"))
      return ("Very Small (<10 students)")
    if (row.class_size_group.includes("Very Large"))
      return ("Very Large (>50 students)")
    return row.class_size_group
}

const cellFormatter = (cell, row) => {
  return (
    <div className="prof-head">
      <Link to={`/course?courseID=${row.course_id}`}>{row.course_id}</Link>
    </div>
  )
}


const ProfessorTable = ({ profData }) => {

  const ref = React.useRef();

  return (
    <Container>
      <div style={{ position: 'relative', height: 240, maxWidth: "100%", margin: "auto", marginTop: "1em", marginBottom: '4em' }}>
      <BootstrapTable tableHeaderClass="prof-table-head" trClassName="prof-table" striped condensed ref={ref} data={profData}>
        <TableHeaderColumn dataField='course_id' dataFormat={cellFormatter} isKey={true} width='100' dataSort={true}>Course</TableHeaderColumn>
        <TableHeaderColumn dataField='Section' width='102' dataSort={true}>Section</TableHeaderColumn>
        <TableHeaderColumn dataField='Term' width='116' dataSort={true} sortFunc={termSort}>Term</TableHeaderColumn>
        <TableHeaderColumn dataField='class_size_group' dataFormat={sizeTruncator} width='190' dataSort={true}>Size</TableHeaderColumn>
        <TableHeaderColumn dataField='GPA' width='110' dataSort={true} dataFormat={pillBadges}>GPA
          <PillLegend />
        </TableHeaderColumn>
        <TableHeaderColumn dataField='A' width='75' dataSort={true}>A</TableHeaderColumn>
        <TableHeaderColumn dataField='B' width='75' dataSort={true}>B</TableHeaderColumn>
        <TableHeaderColumn dataField='C' width='75' dataSort={true}>C</TableHeaderColumn>
        <TableHeaderColumn dataField='D' width='75' dataSort={true}>D</TableHeaderColumn>
        <TableHeaderColumn dataField='F' width='75' dataSort={true}>F</TableHeaderColumn>
        <TableHeaderColumn dataField='W' width='75' dataSort={true}>W</TableHeaderColumn>
      </BootstrapTable>
      </div>
    </Container>
  )
}

export default ProfessorTable