import React, { useEffect, useState } from "react";
import { extractYearFromString } from "../utils/helperUtils";
import { letters } from "../utils/helperUtils";
import { weights } from "../utils/averagingUtils";
import { VictoryChart, VictoryVoronoiContainer, 
    VictoryTooltip, VictoryAxis, VictoryLine,
    VictoryLabel } from 'victory';
import { Row, Col } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

/**
 * Computes grades over time for any particular course, for letters in
 * ["A", "B", "C", "D", "F", "W", and "GPA"].
 * @param {*} courseDataRaw raw course data fetched from api
 */
export const computeAvgGradesOverTime = (courseDataRaw) => {
    const avgOverTime = {};
    for (const data of courseDataRaw) {
        let entry = {...data};
        let year = extractYearFromString(entry["Term"]);
        let currWeight = weights[entry["class_size_group"].toLowerCase()];
        if (!(year in avgOverTime)) {
            avgOverTime[year] = {};
            avgOverTime[year]["weight"] = currWeight;
            for (const letter of letters) {
                avgOverTime[year][letter] = entry[letter];
            }
        } else {
            for (const letter of letters) {
                avgOverTime[year][letter] = (entry[letter] * currWeight + avgOverTime[year][letter] 
                    * avgOverTime[year]["weight"]) / (currWeight + avgOverTime[year]["weight"]);
            }
            avgOverTime[year]["weight"] += currWeight;
        }
    }
    return avgOverTime;
}

// export const computeCoreStatistics = (courseDataRaw) => {
//     for (const data of courseDataRaw) {
//         let entry = {...data};
//         for (const letter of letters) {
//             coreStatistics["max"][letter] = Math.max(coreStatistics["max"][letter], entry[letter]);
//             coreStatistics["min"][letter] = Math.min(coreStatistics["min"][letter], entry[letter]);
//             coreStatistics["range"][letter] = coreStatistics["max"][letter] - coreStatistics["min"][letter];

//         }
//     }

// }

const gpaTicks = [2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75, 4.0];
const pctTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const AvgGradesOverTime = ({ avgGradesOverTime }) => {
    const [letterSelected, setLetterSelected] = useState("GPA");
    const [data, setData] = useState([]);

    useEffect(() => {
        const myData = Object.keys(avgGradesOverTime).map(year => ({
            year,
            average: avgGradesOverTime[year][letterSelected],
        }));
        setData(myData);
    }, [letterSelected, avgGradesOverTime])

    return (
        <div>
            <Row style={{ margin: '0 auto' }}>
                <Col style={{ maxWidth: '35%' }}>
                    <Row style={{ alignItems: 'center', gap: '1em', marginBottom: 0, flexWrap: 'wrap' }}>
                        <h3 style={{ fontSize: '20px' }}>Select letter</h3>
                        <button onClick={() => setLetterSelected("GPA")} className="grade-button">GPA</button>
                        <button onClick={() => setLetterSelected("A")} className="grade-button">A</button>
                        <button onClick={() => setLetterSelected("B")} className="grade-button">B</button>
                        <button onClick={() => setLetterSelected("C")} className="grade-button">C</button>
                        <button onClick={() => setLetterSelected("D")} className="grade-button">D</button>
                        <button onClick={() => setLetterSelected("F")} className="grade-button">F</button>
                        <button onClick={() => setLetterSelected("W")} className="grade-button">W</button>
                    </Row>
                    {/* <Table responsive className='table-striped' style={{ color: '#fff', marginTop: '2em' }}>
                        <thead>
                        <tr>
                            <th>Highest Recorded GPA</th>
                            <th>Lowest Recorded GPA</th>
                            <th>Std. Deviation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>3.1</td>
                            <td>3.6</td>
                            <td>5</td>
                        </tr>
                        </tbody>
                    </Table> */}
                </Col>
                <Col style={{ maxWidth: '65%', bottom: 20, left: 20 }}>
                    <div key={letterSelected} className="chart-victory">
                        <VictoryChart
                            containerComponent={
                                <VictoryVoronoiContainer
                                    labels={({ datum }) => `${datum.year}: ${datum.average.toFixed(2)}${letterSelected === 'GPA' ? '' : '%'}`}
                                    labelComponent={
                                        <VictoryTooltip
                                            style={{ fontSize: 10, fontFamily: 'inherit' }}
                                            pointerLength={0} 
                                            pointerWidth={0}
                                            flyoutPadding={3}
                                            flyoutStyle={{ fill: '#fff', strokeWidth: 1 }}
                                            cornerRadius={0}
                                        />
                                    }
                                />
                            }
                            style={{ parent: { position: 'relative', right: 0, marginLeft: 'auto', fontFamily: 'inherit'} }}
                        >
                            <VictoryLabel
                                text={letterSelected === 'GPA' ? 'Average GPA Over Time' 
                                    : `Average ${letterSelected}% Over Time`}
                                x="50%"
                                y="3"
                                textAnchor="middle"
                                style={{ fontSize: 14, fontFamily: 'inherit', fill: '#fff' }}
                            />
                            <VictoryAxis
                                tickValues={Object.keys(avgGradesOverTime)}
                                tickFormat={Object.keys(avgGradesOverTime)}
                                style={{
                                    axis: { stroke: '#B3B3B3' },
                                    ticks: { stroke: '#B3B3B3' },
                                    tickLabels: { fill: '#B3B3B3', fontSize: 10, fontFamily: 'inherit' },
                                }}
                                label="Years"
                                axisLabelComponent={<VictoryLabel style={{ fontSize: 14, fill: '#fff' }} />}
                            />
                            <VictoryAxis 
                                dependentAxis 
                                tickValues={letterSelected === 'GPA' ? gpaTicks : pctTicks}
                                style={{
                                    axis: { stroke: '#B3B3B3' },
                                    ticks: { stroke: '#B3B3B3' },
                                    tickLabels: { fill: '#B3B3B3', fontSize: 10, fontFamily: 'inherit' },
                                    grid: { stroke: '#575757', strokeWidth: 1.5 },
                                }}
                                label={letterSelected === 'GPA' ? 'GPA' : 'Pct'}
                                axisLabelComponent={<VictoryLabel dx={-12} angle={0} style={{ fontSize: 14, fill: '#fff' }} />} // Adjust font size and color here
                            />
                    
                            <VictoryLine
                                data={data}
                                x="year"
                                y="average"
                                style={{
                                    data: { stroke: '#fff', strokeWidth: 1.5 },
                                }}
                            />

                        </VictoryChart>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default AvgGradesOverTime;