import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { getCourseLoading, getCourseData, getCourseError } from '../selectors/courseDataSelectors';
import PageHeader from './PageHeader'
import AvgGradesTable from './AvgGradesTable';
import AvgCiosTable from "./AvgCiosTable";
import Tooltip from "@material-ui/core/Tooltip";
import { Row, Col } from 'react-bootstrap';

class CoursePageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  handleCourseModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {

    let name = this.props.courseDetails["full_name"];
    let description = this.props.courseDetails["description"]
    let credits = this.props.courseDetails["credits"] ? this.props.courseDetails["credits"] : "N/A"

    return (
      <div className="page-header">
        <PageHeader></PageHeader>

        <h2 className="text-center" style={{ fontSize: "30px" }}>
          {name}
          {description !== null && description.length !== 0 &&
            <Tooltip title="Course Description">
              <IconButton style={{outline: "none"}} color="inherit" aria-label="Course Description" 
                  onClick={this.handleCourseModalOpen}>
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
          }
          {description !== null && description.length !== 0 &&
            <p style={{ fontStyle: 'italic', color: "#8d8d8d", fontSize: '18px', width: '70%', margin: '0 auto' }}>
              {description}
            </p>
          }
        </h2>

        <Row style={{ justifyContent: "spaceBetween", margin: 0 }}>
          <Col style={{ maxWidth: "70%" }}>
            <AvgGradesTable finalAvgs={this.props.finalCourseAvgs}/>
          </Col>
          <Col style={{ maxWidth: "30%" }}>
            <AvgCiosTable finalCiosAvgs={this.props.finalCiosAvgs} isProf={false}/>
          </Col>
        </Row>

        <Modal
          style={{display:'flex', justifyContent:'center', height: 'fit-content'}}
          open={this.state.isModalOpen}
          onClose={this.handleModalClose}
        >
          <div className="modal-content" style={{width: "40vw", marginTop: "12vh", overflow: "auto", maxHeight: "40vh"}}>
            <div className="modal-header">
              <h5 className="modal-title">{`${name} (${credits} credits)`}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{outline: "none"}} onClick={this.handleModalClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {description}
            </div>
          </div>
        </Modal>
      </div>
      
    )
  }
}


const mapStateToProps = state => ({
  data: getCourseData(state),
  error: getCourseError(state),
  isLoading: getCourseLoading(state)
})

CoursePageHeader.propTypes = {
  data: PropTypes.array,
  error: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps
)(withRouter(CoursePageHeader));