import {weights} from "./averagingUtils"
export const letters = ["GPA", "A", "B", "C", "D", "F", "W"];

/**
 * Computes and returns a list of unique profesors from raw course data
 * @param {*} courseDataRaw 
 * @returns list of unique professors
 */
export const getProfFilter = (courseDataRaw) => {
    const profFilter = {};
    for (const data of courseDataRaw) {
        if (!(data.instructor_name in profFilter)) {
            profFilter[data.instructor_name] = true;
        }
    }
    return profFilter;
}
  
/**
 * Computes a filter for years based on input data (could be courseData or profData)
 * @param {*} dataRaw 
 * @returns dict of years to booleans
 */
export const getTermFilter = (dataRaw) => {
    const termFilter = {};
    for (const data of dataRaw) {
        let entry = {...data};
        let term = entry["Term"];
        if (!(term in termFilter)) {
            termFilter[term] = true;
        }
    }
    return termFilter;
}

/**
 * Computes relevant years for the data.
 * @param {*} dataRaw raw course data
 * @returns array of relevant years
 */
export const getRelevantYears = (dataRaw) => {
    const relYears = [];
    for (const data of dataRaw) {
        let entry = {...data};
        let year = extractYearFromString(entry["Term"]);
        if (!(relYears.includes(year))) {
            relYears.push(year);
        }
    }
    return relYears;
}
  
export const roundAvgs = (myAvgs) => {
    const updatedAvgs = {...myAvgs};
    Object.keys(updatedAvgs).forEach((key) => {
        for (const letter of letters) {
            if (letter === "GPA") {
                updatedAvgs[key][letter] = updatedAvgs[key][letter].toFixed(2);
            } else {
                updatedAvgs[key][letter] = updatedAvgs[key][letter].toFixed(1);
            }
        }
    });
    return updatedAvgs;
};
  
/**
 * Computes separate terms active dictionary for each professor in raw course data.
 * @param {*} courseDataRaw raw course data
 * @returns dict of terms active (prof -> {selectedTerms: [], unSelectedTerms: []})
 */
export const computeTermsActive = (courseDataRaw, termFilter) => {
    const dict = {};
    for (const data of courseDataRaw) {
        let entry = {...data};
        let prof = entry["instructor_name"];
        let term = entry["Term"];
        if (!(prof in dict)) {
            dict[prof] = {};
            dict[prof]["selected_terms"] = [];
            dict[prof]["unselected_terms"] = [];
        }
        if (termFilter[term]) {
            if (!dict[prof]["selected_terms"].includes(term)) {
                dict[prof]["selected_terms"].push(term);
            }
        } else {
            if (!dict[prof]["unselected_terms"].includes(term)) {
                dict[prof]["unselected_terms"].push(term);
            }
        }
    }
    const sortedDict = Object.fromEntries(
        Object.entries(dict).sort((a, b) => extractYearFromString(b[0]) - extractYearFromString(a[0]))
    );

    return sortedDict;
}

/**
 * Extracts a year from a string.
 * @param {*} inputString input string
 * @returns numerical year
 */
export const extractYearFromString = (inputString) => {
    const match = inputString.match(/\b\d{4}\b/);
    return match ? parseInt(match[0]) : null;
}

export const filterData = (profData, courseFilter, gpaFilter, termFilter, classSizeFilter) => {
    let newData = [];
    for (const entry of profData) {
        if (!courseFilter[entry["course_id"]] || !isGPAInFilteredRange(entry["GPA"], gpaFilter) || !termFilter[entry["Term"]] 
                || !classSizeFilter[entry["class_size_group"].toLowerCase()]) {
            continue;
        }
        newData.push(entry);
    }
    return newData;
}

/**
 * Creates a GPA filter.
 * @returns gpa filter
 */
export const getGPAFilter = () => {
    return {
        "3.50 - 4.00": true,
        "3.00 - 3.49": true,
        "2.50 - 2.99": true,
        "2.00 - 2.49": true,
        "< 2.00": true
    }
}

export const getClassSizeFilter = () => {
    const filters = {}
    for (const [key, value] of Object.entries(weights)) {
        filters[key] = true;
    }
    return filters;
}

/**
 * Determines whether a numerical GPA (float) is within the ranges specified by a dictionary.
 * @param {*} gpa gpa to test
 * @param {*} gpaFilter dictionary to test on
 * @returns boolean
 */
export const isGPAInFilteredRange = (gpa, gpaFilter) => {
    for (const range in gpaFilter) {
        if (gpaFilter[range]) {
            const [lower, upper] = range.split(' - ');
            if (upper === undefined) {
                // Handle the case for "< 2.00"
                if (parseFloat(gpa) < parseFloat(lower)) {
                    return true;
                }
            } else {
                if (parseFloat(gpa) >= parseFloat(lower) && parseFloat(gpa) <= parseFloat(upper)) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const getClassSize = (size) => {
    let keySet = Object.keys(weights);
    let ranges = [[0,9],[10,20],[21,30],[31,49]];
    for (let i = 0; i < 4; i++) {
        if ((ranges[i][0] <= size) && (size <= ranges[i][1])) {
            return keySet[i];
        }
    }
    if (size >= 50) {
        return keySet[4];
    }
}

/**
 * Creates a course filter given the raw prof data.
 * @param {*} profDataRaw raw prof data
 * @returns dict of courses to booleans
 */
export const getCourseFilter = (profDataRaw) => {
    const courseFilter = {};
    for (const data of profDataRaw) {
        if (!(data.course_id in courseFilter)) {
            courseFilter[data.course_id] = true;
        }
    }
    return courseFilter;
}