import { AUTH_URL } from "../constants/Endpoints";

export function checkSSO() {
    // if (window.location.href.includes("/sso")) {
    //     const queries = window.location.href.split('/sso')[1]
    //     console.log(AUTH_URL + queries)
    //     alert(AUTH_URL + queries)
    //     window.location.href = AUTH_URL + queries;
    // }
    if (window.location.href.includes("/sso")) {
        const queries = "?" + window.location.href.split('/sso?')[1];
        window.location.href = AUTH_URL + queries;
    }
}
