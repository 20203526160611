import React, { Component, Fragment } from 'react';
import SearchBar from './SearchBar'
import { Col, Row, } from 'react-bootstrap'
import Grid from '@material-ui/core/Grid';
import illo from '../images/illofinal-1000.png'
import DarkModeButton from './DarkModeButton'
import Modal from '@material-ui/core/Modal';
import Button from 'react-bootstrap/Button';
import BookIcon from '@material-ui/icons/Book';
import { useMediaQuery } from 'react-responsive';
import { Background } from 'victory';
import { checkAuthentication } from '../sso/handler'


class Header extends Component {
  constructor(props) {
    super(props);
    // only pop up modal if user has visited the website before
    if (!localStorage.noFirstVisit) {
      this.state = {
        isModalOpen: true
      }
      localStorage.noFirstVisit = "1";
    } else {
        this.state = {
        isModalOpen: false
      }
    }
    this.handleModalOpen=this.handleModalOpen.bind(this);
    this.handleModalClose=this.handleModalClose.bind(this);
  }
  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  

  render() {
    return (
      <Fragment>
        <Grid>
          <Col style={{ marginTop: '1vh' }}><DarkModeButton /></Col>
          <Button style={{float:'right' ,font:'Arial'}} onClick={this.handleModalOpen}>Contribute to the Syllabus Repo!</Button>
        </Grid>
        <Grid container item xs={12} spacing={2} direction="row-reverse" style={{ marginTop: "12vh", marginLeft: "0.5em", marginBottom: "8em" }} >
          <Col xs={12} md={6}>
            <img class="illo" src={illo} alt="3D user interface illustration of Course Critique" width="500" height="400"></img>
          </Col>
          <Col>
            <Row><h1 style={{ textAlign: 'left', fontWeight: '700' }}>Course <a style={{ color: '#E8C343' }}>Critique</a></h1></Row>
            <Row><h3 style={{ textAlign: 'left' }}>Type in a Professor or Course Name</h3></Row>
            <br></br>
            <Row><SearchBar format={2} /></Row>
          </Col>
        </Grid>
        <Modal
          style={{display:'flex',justifyContent:'center'}}
          open={this.state.isModalOpen}
          onClose={this.handleModalClose}
        >
          <div className="modal-content" style={{width: "60%", marginTop: "12vh", 
          height: "40vh", overflow:'auto', paddingRight:'1.5rem', paddingLeft:'1.5rem', paddingBottom: '1rem'}}>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" 
                style={{marginTop:'10px', marginRight:'10px', outline: "none", width:'fit-content', marginLeft:'auto'}} onClick={this.handleModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="mb-2" style={{ height:"55px", marginTop:'8px', paddingBottom:'60px'}}>
              <h1 style={{width:'100%', textAlign: 'center', fontWeight: '600'}}>Syllabus Initiative</h1>
            </div>
            <div className="mb-2">
              <div style={{textAlign:'center', fontSize: '16px',  marginBottom:'15px', marginTop:'20px', paddingTop:'7px', width:'100%'}}>
                Hey everyone! SGA IT is working on a crowdsourcing project to aggregate syllabi of previous semesters.
                If you have any syllabi from the last two years, please consider uploading it using the Qualtrics form or link in the footer below.
                Syllabi uploaded last semester have been released and can be viewed by clicking the
                <BookIcon/> icon within the table.<br></br>

                <a href="mailto:it@sga.gatech.edu, gtsga.syllabusrepo@gmail.com?subject=Course Critique Syllabus Repo Question/Concern">Questions or Concerns?</a>
              </div>
              <div class="col text-center">
                <Button variant="primary" href="https://gatech.co1.qualtrics.com/jfe/form/SV_50y7AjbKLsENPFk" 
                  size="md" target="_blank">
                  Upload Syllabi</Button>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

export default Header;