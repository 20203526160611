import React from "react";
import Table from 'react-bootstrap/Table';

const AvgGradesTable = ({ finalAvgs }) => {

  let avgGPA = finalAvgs.GPA ? 
      finalAvgs.GPA.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : "";
  return (
    <div className="table-header center-table">
      <Table responsive className='table-striped'>
        <thead>
          <tr>
            <th>Avg GPA</th>
            <th>A%</th>
            <th>B%</th>
            <th>C%</th>
            <th>D%</th>
            <th>F%</th>
            <th>W%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{avgGPA}</td>
            <td>{finalAvgs.A}</td>
            <td>{finalAvgs.B}</td>
            <td>{finalAvgs.C}</td>
            <td>{finalAvgs.D}</td>
            <td>{finalAvgs.F}</td>
            <td>{finalAvgs.W}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
export default AvgGradesTable;
